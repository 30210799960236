import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { CarouselTouch } from '../../v2024/Carousel/carousel-touch';

const Clippings = ({ lang = 'en' }) => {
	const query = useStaticQuery(graphql`
		query {
			clippings: allStrapiNotices {
				edges {
					node {
						link
						description
						media {
							name
							media {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 280, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						locale
					}
				}
			}
			helpers: allMarkdownRemark(filter: { frontmatter: { name: { eq: "helpers" } } }) {
				edges {
					node {
						frontmatter {
							lang
							clippings {
								title
							}
						}
					}
				}
			}
		}
	`);
	const clippings = query.clippings.edges.filter((item) => item.node.locale === lang);
	const helpers = query.helpers.edges.filter((item) => item.node.frontmatter.lang === lang)[0].node.frontmatter;

	return (
		clippings.length > 0 && (
			<div className="main__section main__section--100">
				<div className="container container--gray">
					<h2>{helpers.clippings.title}</h2>
					<CarouselTouch slides={clippings} showArrows showIndicators useTestimonial />
				</div>
			</div>
		)
	);
};

export default Clippings;
